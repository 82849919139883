document.addEventListener('DOMContentLoaded', function() { //Cuando carga la pagina completa
    iniciarApp();   // inicia esta funcion
});

function iniciarApp() {
    // crearGaleria();
    // scrollNav();
    // navegacionFija();
    addEventListener(); // llamamos esta funcion para que este a al escucha
}

function addEventListener() {
    const mobileMenu = document.querySelector('.mobile-menu');
    const enlaces = document.querySelectorAll('.navegacion-principal a');
    // ponemos a la escucha de un click al menu de barras
    mobileMenu.addEventListener('click', navegacionResponsive);
    enlaces.forEach(function(enlace){
        enlace.addEventListener('click', function() {
            borrarMenuActivo();
            enlace.classList.add('menu-activo');
        })
    });
}

function borrarMenuActivo() {
    const enlaces = document.querySelectorAll('.navegacion-principal a');
    enlaces.forEach(function(enlace){
        enlace.classList.remove('menu-activo');
    })
}

// seactiva al darle click al menu de hamburguesa
function navegacionResponsive() {
    // seleccionamos la navegacion principal para poder manipularla
    const navegacion = document.querySelector('.navegacion-principal');

    // // si la navecion-principal tiene la clase mostrar
    // if(navegacion.classList.contains('mostrar')) {
    //     navegacion.classList.remove('mostrar');
    // } else {
    //     navegacion.classList.add('mostrar');
    // }

    // con toggle si no tiene mostrar en la clase la agrega si la tiene la quita
    navegacion.classList.toggle('mostrar');
}

function menuActivo(enlace) {
    enlace.addEventListener('click', function() {
        enlace.classList.add('menu-activo');
    })
}




function navegacionFija() {
    const barra = document.querySelector('.menu-recepcion');
    const descripcion = document.querySelector('.contenido-habitaciones');
    const body = document.querySelector('body');

    window.addEventListener('scroll', function() {
        // console.log(descripcion.getBoundingClientRect);

        if(descripcion.getBoundingClientRect().top < 0) {
            barra.classList.add('fijo');
            body.classList.add('body-scroll');
        } else {
            barra.classList.remove('fijo');
            body.classList.remove('body-scroll');
        }
    });
}
    

// crea un scrool suave solo que hay que agregarle un id a los enlaces
function scrollNav() {
    const enlaces = document.querySelectorAll('.navegacion-principal a');
    enlaces.forEach( function(enlace) {
        enlace.addEventListener('click', function(e) {
            e.preventDefault();
            const seccion = document.querySelector(e.target.attributes.href.value);
            seccion.scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
}

function crearGaleria() {
    const galeria = document.querySelector('.fotos-habitaciones');
    for(let i =1; i < 6; i++) {
       const imagen = document.createElement('PICTURE');
       imagen.innerHTML = `
            <source srcset="build/img/${i}.webp" type="image/webp">
            <source srcset="build/img/${i}.jpg" type="image/jpeg">
            <img loading="lazy" src="build/img/${i}.jpg" alt="Imagen Habitación">
       `;

       galeria.appendChild(imagen);

       imagen.onclick = function() {
        mostrarImagen(i);
       }

       console.log(imagen);
    }
}

function mostrarImagen(id) {
       const imagen = document.createElement('PICTURE');
       imagen.innerHTML = `
            <source srcset="build/img/${id}.webp" type="image/webp">
            <source srcset="build/img/${id}.jpg" type="image/jpeg">
            <img loading="lazy" src="build/img/${id}.jpg" alt="Imagen Habitación">
       `;

       // Crear overlay con imagen
       const overlay = document.createElement('DIV');
       overlay.appendChild(imagen);
       overlay.classList.add('overlay');
       overlay.onclick = function() {
           const body = document.querySelector('body'); 
           body.classList.remove('fijar-body');
           overlay.remove();
       }

       // Boton Cerrar Foto
       const cerrarFoto = document.createElement('P');
       cerrarFoto.textContent = "X";
       cerrarFoto.classList.add('btn-cerrar');    

       cerrarFoto.onclick = function() {
           const body = document.querySelector('body'); 
           body.classList.remove('fijar-body');
           overlay.remove();
       }

       overlay.appendChild(cerrarFoto);

       // Anade al HTML
       const body = document.querySelector('body');
       body.appendChild(overlay);       
       body.classList.add('fijar-body');
}    
